<template>
  <div class="actions">
    <a
      href="javascript:void(0)"
      class="btn btn-primary btn-icon"
      data-toggle="modal"
      data-target="#update-action-post-mortem"
      @click.prevent="setSelected"
    > 
      <i class="icofont icofont-pencil" />
    </a>
    <a
      href="javascript:void(0)"
      class="btn btn-danger btn-sm"
      @click.prevent="deletingAction"
    > 
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { DELETE_ACTION_POST_MORTEM } from '../../../graphql/identification';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    methods: {
        ...mapMutations({
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        setSelected(){
            this.setSelectedObject(this.object)
        },
        deletingAction(){
            this.$apollo.mutate({
                mutation: DELETE_ACTION_POST_MORTEM,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Action post mortem ${this.object.libelle} at ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting action post ${this.object.libelle}`)
                }
              }).then(() => {
                
              })

        }
    }
}
</script>

<style>

</style>